/*
 * @Author: your name
 * @Date: 2021-04-21 10:33:06
 * @LastEditTime: 2021-04-21 10:39:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\service\user.js
 */

import axios from '../utils/axios'

// 获取消息列表
export function getMessage(params) {
  return axios.get('/api/message/list', { params })
}

// 存消息
export function setMessage(params) {
  return axios.post('/api/message/set', params)
}
