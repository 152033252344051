<!--
 * @Author: your name
 * @Date: 2021-04-27 16:12:22
 * @LastEditTime: 2021-04-27 17:35:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yaaami-frontend-user\src\components\NavBar.vue
-->

<template>
  <div class="add_card">
    <!--  eslint-disable-next-line vue/no-v-model-argument vue/attribute-hyphenation -->
    <van-dialog v-model:show="show" title="Add card" :showConfirmButton="false" :showCancelButton="false" @confirm="addConfirm" @cancel="addCancel">
      <div class="add_form">
        <form id="card-form">
          <div class="info_group">
            <span class="left_title">Full name(on then card)</span>
            <div class="right_info">
              <input id="card-name" type="text" class="set_input_style van-field__control" placeholder="Full name">
            </div>
          </div>
          <div class="info_group">
            <span class="left_title">Card number</span>
            <div class="right_info">
              <div id="card-number" class="set_input_style" />
            </div>
          </div>
          <div class="row_info">
            <div class="info_group">
              <span class="left_title">Expriation</span>
              <div class="right_info">
                <div id="card-expiry" class="set_input_style" />
              </div>
            </div>
            <div class="info_group">
              <span class="left_title">CVC</span>
              <div class="right_info">
                <div id="card-cvc" class="set_input_style" />
              </div>
            </div>
          </div>
          <div class="van-hairline--top van-dialog__footer">
            <div class="van-button--default van-dialog__cancel" @click="addCancel">
              <div class="van-button__content">
                <span class="van-button__text">Cancel</span>
              </div>
            </div>
            <button type="submit" class="el-button el-button--primary">
              <div v-if="creditLock" class="spinner" />
              <div v-else class="van-button__text">
                Submit
              </div>
            </button>
          </div>
        </form>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { onMounted, toRefs, watch } from 'vue'
import { stripePay } from '../mixin/stripePay.js'
import { Toast } from 'vant'

export default {
  mixins: [stripePay],
  props: {
    addDialogShow: {
      type: Boolean,
      default: false
    }
  },
  // emits: ['closeAddDialog'],
  setup(props, ctx) {
    const state = {
      show: false,
      boundHandler: null,
      creditLock: false,
      setupIntent: null,
      key: ''
    }
    watch(props, () => {
      state.show = props.addDialogShow
      if (state.show) {
        setTimeout(() => {
          initStripe()
        }, 20)
      }
    })

    onMounted(() => {
    })
    const addConfirm = () => {

    }
    const addCancel = () => {
      ctx.emit('closeAddDialog')
    }
    const initStripe = async() => {
      const elementStyles = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4'
          }
        }
      }
      const elementClasses = {
        base: 'stripe-input el-input__inner'
      }
      // eslint-disable-next-line no-undef
      const stripe = Stripe(stripePay.state.server_pub_key)
      const elements = stripe.elements()

      const cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses
      })
      cardNumber.mount('#card-number')

      const cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses
      })
      cardExpiry.mount('#card-expiry')

      const cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses
      })
      cardCvc.mount('#card-cvc')
      registerElements([cardNumber, cardExpiry, cardCvc], stripe)
    }
    const registerElements = (elements, stripe) => {
      const form = document.getElementById('card-form')
      state.boundHandler = formSubmit.bind(this, elements, stripe)
      form.addEventListener('submit', state.boundHandler)
    }
    const formSubmit = async(elements, stripe) => {
      event.preventDefault()
      const form = document.getElementById('card-form')
      const name = form.querySelector('#card-name').value
      if (!name) {
        Toast.fail('Please enter the cardholder name')
        return false
      }
      if (state.creditLock) return false
      state.creditLock = true
      state.setupIntent = await stripePay.methods.createSetupIntent()
      stripe.confirmCardSetup(
        state.setupIntent.client_secret, {
          payment_method: {
            card: elements[0],
            billing_details: {
              name
            }
          }
        }
      ).then((result) => {
        if (result.error) {
          state.creditLock = false
          Toast.fail(result.error.message)
        } else {
          state.creditLock = false
          successBack()
        }
      })
    }
    const successBack = () => {
      Toast.success('Credit card added successfully')
      setTimeout(() => {
        ctx.emit('addCardSuccess')
      }, 200)
    }
    return {
      ...toRefs(state),
      addConfirm,
      addCancel,
      initStripe,
      registerElements,
      formSubmit,
      successBack
    }
  }
}
</script>

<style lang="less">
@import "../common/style/mixin";
.add_card {
  .add_form {
    width: 100%;
    box-sizing: border-box;
    padding: 0.4rem;
    padding-bottom: 0;
    .el-button.el-button--primary {
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: auto;
      flex: 1;
      border-left: 1px solid #ebeef5;
      .van-button__text {
        color: crimson;
      }
    }
    .info_group {
      margin-bottom: 0.2667rem;
      margin-right: 2px;
      .left_title {
        width: 100%;
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.4267rem;
      }
      .van-cell {
        border: 1px solid #ebeef5;
        border-radius: 0.1333rem;
      }
      .set_input_style {
        border: 1px solid #ebeef5;
        border-radius: 0.1333rem;
        padding: 10px 16px;
        line-height: 24px;
        font-size: 14px;
      }
    }
    .row_info {
      width: 100%;
      display: flex;
      flex-direction: row;
      .info_group {
        flex: 1;
      }
    }
  }
}
</style>
