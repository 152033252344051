<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="order">
    <header-bar :show-menu="true" />
    <!--  eslint-disable-next-line vue/no-v-model-argument -->
    <van-tabs v-model:active="tabActive" :ellipsis="false" @change="tabChange">
      <van-tab v-for="item in tabList" :key="item.id" :title="item.name" />
    </van-tabs>
    <div class="content">
      <div class="order_list">
        <!--  eslint-disable-next-line vue/no-v-model-argument -->
        <van-list v-model:loading="loading" :finished="finished" finished-text="No more" @load="onLoad">
          <div
            v-for="(item, index) in orderList"
            :key="index"
            class="order_item"
            @click="goToDetail(item)"
          >
            <div class="order_time">
              <span>{{ $filters.timeFilter(item.created_at) }}</span>
              <span :class="item.status == 0 ? 'not_paid' : ''">
                {{ orderStatus[item.status] }}
              </span>
            </div>
            <div class="good_list">
              <p v-for="(good, goodIndex) in item.goodsList" :key="goodIndex">
                {{ good.product_name }} * {{ good.num }}
              </p>
            </div>
            <div class="total">
              <span>
                <i>Total quantity:</i>
                {{ item.quantity }}
              </span>
              <span>
                <i>Summary:</i>
                ${{ $filters.priceHandle(item.allPrice) }}
              </span>
            </div>
            <div
              v-if="item.refund_status === 8 || item.refund_status === 9"
              class="refund"
            >
              <van-tag v-if="item.refund_status === 8 && item.allPrice !== item.refundMoney" color="#1e9eb3">
                fully refunde
              </van-tag>
              <van-tag v-else color="#1e9eb3">
                {{ refundStatus[item.refund_status] }}
              </van-tag>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <nav-bar />
  </div>
</template>

<script>
// @ is an alias to /src
// import { getLocal } from '@/common/js/utils'
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, Notify } from 'vant'
import HeaderBar from '@/components/HeaderBar'
import navBar from '@/components/NavBar'
import { getOrder } from '@/service/user'
import { useStore } from 'vuex'

export default {
  name: 'Home',
  components: {
    navBar,
    HeaderBar
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      loading: true,
      finished: false,
      tabList: [
        {
          name: 'All',
          id: 0
        },
        {
          name: 'To ship',
          id: 1
        },
        {
          name: 'Completed',
          id: 6
        }
      ],
      tabActive: -1,
      orderList: [],
      pageIndex: 1,
      pageSize: 50,
      orderStatus: {},
      refundStatus: {},
      creditConfig: 0
    })
    onMounted(() => {
      state.orderStatus = store.state.orderStatus
      state.refundStatus = store.state.refundStatus
      state.creditConfig = store.state.creditConfig
    })
    const tabChange = () => {
      state.orderList = []
      getOrderlist()
    }
    const getOrderlist = async() => {
      Toast.loading({
        message: 'loadin...',
        forbidClick: true
      })
      let orderStatus = 0
      if (state.tabActive === 0) {
        orderStatus = -1
      } else if (state.tabActive === 1) {
        orderStatus = 1
      } else if (state.tabActive === 2) {
        orderStatus = 4
      } else if (state.tabActive === 3) {
        orderStatus = 6
      }
      const res = await getOrder({
        status: orderStatus,
        page_index: state.pageIndex,
        page_size: state.pageSize
      })
      if (res.data.code) {
        Notify({
          message: res.data.message,
          color: '#ad0000',
          background: '#ffe1e1'
        })
      } else {
        const list = res.data.data.data
        const total = res.data.data.total
        const newList = state.orderList
        list.forEach((item) => {
          if (item && item.order) {
            const obj = item.order
            let deliver_cost = 0
            // 计算配送费
            if (item.sale_time_deliver) {
              const costList = Object.values(item.sale_time_deliver)
              if (costList.length) {
                deliver_cost = costList.reduce((next, curr) => next + curr, 0)
              }
            }

            // 直接用接口返回的相加
            obj.allPrice = obj.cost + obj.tip + deliver_cost + obj.credit_card_fees
            obj.goodsList = item.detail
            if (obj.refund_status === 8) {
              let refundMoney = 0
              item.refund && item.refund.forEach(ref => {
                refundMoney += ref.price
              })
              obj.refundMoney = refundMoney
            }
            let totalNum = 0
            if (item.detail) {
              item.detail.forEach(ele => {
                totalNum += ele.num
              })
            }
            obj.quantity = totalNum
            newList.push(obj)
          }
        })
        state.orderList = newList
        state.loading = false
        if (total <= newList.length) {
          state.finished = true
        }
      }
      Toast.clear()
    }
    const onLoad = () => {
      if (!state.finished) {
        state.pageIndex++
        state.loading = true
        getOrderlist()
      }
    }
    const goToDetail = item => {
      router.push({
        path: `/orderDetail`,
        query: { orderId: item.order_id }
      })
    }

    return {
      ...toRefs(state),
      goToDetail,
      getOrderlist,
      onLoad,
      tabChange
    }
  }
}
</script>
<style lang="less">
.order {
  .content {
    width: 100%;
    height: calc(100vh - 140px);
    overflow: auto;
    .order_list {
      width: 100%;
      padding: 0.2667rem;
      box-sizing: border-box;
      .order_item {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        margin-bottom: 0.266667rem /* 20/75 */;
        background: #fff;
        padding: 0.266667rem /* 20/75 */;
        padding-top: 0;
        .order_time {
          width: 100%;
          height: 1.0667rem;
          line-height: 1.0667rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid #e5e5e5;
          font-size: 0.4rem;
          color: #aaa;
        }
        .not_paid {
          color: crimson;
        }
        .good_list {
          width: 100%;
          padding: 0.2667rem;
          box-sizing: border-box;
          p {
            width: 100%;
            min-height: 0.5333rem;
            line-height: 0.5333rem;
            margin: 0;
            font-size: 0.4rem;
            color: #999;
          }
        }
        .total {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 0.4267rem;
          padding: 0 0.2667rem;
          color: #666;
          i {
            font-style: normal;
            font-size: 0.3733rem;
          }
        }
        .refund {
          position: absolute;
          top: 1.3rem;
          right: 0.266667rem;
        }
      }
    }
  }
}
</style>
