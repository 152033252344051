<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="order_detail">
    <van-nav-bar
      title="Order evaluate"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="order_content">
      <van-notice-bar
        v-if="goodsDetail.appraise_status == 1"
        :scrollable="false"
        text="You have commented on this product."
      />
      <div class="content">
        <div class="user_info good_list">
          <div class="goods_item">
            <div class="left">
              <img :src="goodsDetail.pictures" alt="" srcset="">
            </div>
            <div class="right">
              <div class="name_con">
                <p class="name">
                  {{ goodsDetail.name }}
                </p>
              </div>
              <div class="all_price">
                <span class="price">
                  <i>$</i>{{ $filters.priceHandle(goodsDetail.price) }} * {{ goodsDetail.num }}
                </span>
                <span class="price">
                  <i>$</i>{{ $filters.priceHandle(goodsDetail.price) * goodsDetail.num }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="evaluate_score">
          <p>Score</p>
          <van-rate
            v-model="evaluateScore"
            color="#ffd21e"
            :readonly="goodsDetail.appraise_status == 1"
            :count="5"
          />
        </div>
        <van-field
          v-model="comment"
          rows="4"
          autosize
          :disabled="goodsDetail.appraise_status == 1"
          label=""
          type="textarea"
          placeholder="Evaluating goods from multiple perspectives can help more buyers"
        />
        <van-uploader
          v-if="goodsDetail.appraise_status != 1"
          v-model="fileList"
          :after-read="asyncBeforeRead"
          accept="image/*"
          multiple
        />
        <div class="comment_img_list">
          <div v-for="(url, i) in pictureArr" :key="i" class="img_style">
            <van-image
              width="2rem"
              height="2rem"
              fit="cover"
              :src="url"
            />
          </div>
        </div>
        <div v-for="(item, index) in againList" :key="index" class="follow_evaluate">
          <p>{{ $filters.timeFilter(item.updated_at) }} comments</p>
          <div class="comment">
            {{ item.content }}
          </div>
        </div>
        <!-- accept="video/*,image/*" multiple /> -->
        <div v-if="goodsDetail.appraise_status != 1" class="go_save">
          <van-button round :color="primaryColor" type="primary" :loading="btnLoading" block @click="goSave">
            Publish
          </van-button>
        </div>
        <div v-else class="go_save">
          <van-button round :color="primaryColor" type="primary" block @click="showAddSave">
            Additional comments
          </van-button>
        </div>
      </div>
      <!--  eslint-disable-next-line vue/no-v-model-argument -->
      <van-popup v-model:show="evalShow" position="bottom" :style="{ height: '30%' }">
        <div class="eval_show">
          <van-cell-group>
            <van-field
              v-model="addComment"
              type="textarea"
              row="2"
              :autofocus="true"
              label=""
              placeholder=""
            />
          </van-cell-group>
          <van-button round type="primary" class="publish" :color="primaryColor" :loading="btnLoading" plain block @click="goPublish">
            Publish
          </van-button>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { getLocal } from '@/common/js/utils'
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, Notify } from 'vant'
import { stripePay } from '../mixin/stripePay.js'
import { ref } from 'vue'
import { uploadImg, setEvaluate, evaluateDetail } from '@/service/user'

export default {
  name: 'Home',
  mixins: [stripePay],
  setup() {
    const router = useRouter()
    const state = reactive({
      goodsDetail: {},
      evaluateScore: ref(0),
      message: '',
      fileList: [],
      uploadImgList: [],
      comment: '',
      videoList: [],
      pictureArr: [],
      evalShow: false,
      addComment: '',
      btnLoading: false,
      againList: []
    })
    onMounted(async() => {
      var obj = localStorage.getItem('evaluateObj')
      if (obj) {
        state.goodsDetail = JSON.parse(obj)
        if (state.goodsDetail.appraise_status === 1) {
          getEvaluateDetail()
        }
      }
    })
    const getEvaluateDetail = async() => {
      const res = await evaluateDetail({ order_id: state.goodsDetail.orderId })
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        const list = res.data.data
        let data = {}
        const againList = []
        list.forEach(item => {
          if (item.type === 1) {
            data = item
          } else {
            againList.push(item)
          }
        })
        state.comment = data.content
        state.evaluateScore = data.product_score
        state.pictureArr = data.picture_urls.split(',')
        state.againList = againList
      }
    }
    const onClickLeft = () => {
      router.go(-1)
    }
    const asyncBeforeRead = (file) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async(resolve, reject) => {
        file.status = 'uploading'
        file.message = 'Uploading...'
        // 获取后缀
        const uploadFileNameArr = file.file.name.split('.')
        var uploadFileName = '.' + uploadFileNameArr[uploadFileNameArr.length - 1]
        var timestamp = new Date().getTime()
        var tempfile = new File([file.file], timestamp + uploadFileName)
        const formData = new FormData()
        formData.append('upload', tempfile)
        const res = await uploadImg(formData)
        if (res.data.code) {
          file.status = 'failed'
          file.message = 'Failed'
        } else {
          file.status = ''
          file.message = ''
          const url = res.data.data.f
          state.uploadImgList.push(url)
        }
      })
    }
    const goSave = async() => {
      if (state.evaluateScore === 0) {
        Notify({ message: 'Please give a score', color: '#ad0000', background: '#ffe1e1' })
        return false
      }
      if (!state.comment) {
        Notify({ message: 'Please add comments', color: '#ad0000', background: '#ffe1e1' })
        return false
      }
      state.btnLoading = true
      const res = await setEvaluate({
        type: 1,
        product_score: state.evaluateScore,
        order_id: state.goodsDetail.orderId,
        product_id: state.goodsDetail.product_id,
        picture_urls: state.uploadImgList,
        video_urls: state.videoList,
        content: state.comment
      })
      if (res.data.code) {
        state.btnLoading = false
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        state.btnLoading = false
        Toast.success('Add success')
        onClickLeft()
      }
    }
    const showAddSave = async() => {
      state.evalShow = true
    }
    const goPublish = async() => {
      if (!state.addComment) {
        Toast('Please input the content')
        return false
      }
      state.btnLoading = true
      const res = await setEvaluate({
        type: 2,
        product_score: state.evaluateScore,
        order_id: state.goodsDetail.orderId,
        product_id: state.goodsDetail.product_id,
        picture_urls: [],
        video_urls: [],
        content: state.addComment
      })
      if (res.data.code) {
        state.btnLoading = false
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        state.btnLoading = false
        Toast.success('Add success')
        onClickLeft()
      }
    }
    return {
      ...toRefs(state),
      onClickLeft,
      goSave,
      asyncBeforeRead,
      getEvaluateDetail,
      goPublish,
      showAddSave
    }
  }
}
</script>
<style lang="less">
.order_detail {
  overflow: hidden;
  .order_content {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 96px);
  }
  .content {
    width: calc(100% - 0.5333rem);
    margin: 0.2667rem auto;
    overflow: auto;
    background: #fff;
    padding: 0.2667rem;
    box-sizing: border-box;
    border-radius: 5px;
    .top_title {
      width: 100%;
      height: 1.0667rem;
      line-height: 1.0667rem;
      font-size: 0.4267rem;
      color: #333;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .go_pay {
        width: 80px;
      }
    }
    p {
      font-size: 0.4rem;
      color: #666;
      margin: 0;
      line-height: .8rem;
    }
    .order_id_style {
      font-size: 0.32rem;
    }
  }
  .good_list {
    width: 100%;
    padding: 0 0.2667rem;
    box-sizing: border-box;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .goods_item {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      background: #fff;
      padding: .266667rem /* 20/75 */ 0;
      align-items: center;
      border-bottom: 1px solid #f2f3f5;
      &:last-child{
        margin-bottom: 0;
        border-bottom: none;
      }
      .goods_radio {
        margin-right: 0.1333rem;
        display: flex;
        align-items: center;
      }
      .left {
        width: 2rem /* 180/75 */;
        height: 2rem /* 180/75 */;
        overflow: hidden;
        display: flex;
        align-items: center;
        border-radius: 0.2667rem;
        img {
          width: 100%;
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 0.1rem 0 0.1rem 0.4rem;
        .name_con {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0.3rem;
          .name {
            line-height:0.8rem;
            margin: 0;
            font-size: .426667rem /* 32/75 */;
            font-weight: 500;
          }
          .to_evaluate {
            // width: 3rem;
            margin-left: 0.2rem;
          }
        }
        .des {
          font-size: .373333rem /* 28/75 */;
          width: 100%;
          text-align: right;
          color: #ed6a0c;
        }
        .all_price {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          .price {
            font-size: .453333rem /* 34/75 */;
            font-weight: 500;
            i {
              font-style: normal;
              font-size: 0.3733rem;
            }
          }
        }
      }
    }
  }
  .good_title {
    font-size: 0.3733rem;
    height: 0.9333rem;
    line-height: 0.9333rem;
  }
  .evaluate_score {
    width: 100%;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0.2667rem;
    p {
      margin-bottom: 0.3rem;
      font-size: 0.4267rem;
    }
    .van-rate__icon {
      font-size: 0.6rem;
    }
  }
  .follow_evaluate {
    font-size: 0.3rem;
    p {
      color: #ed6a0c;
      font-size: 0.3rem;
    }
  }
  .go_save {
    padding: 0.2667rem 0;
  }
  .van-uploader__preview-image, .van-uploader__upload{
    width: 1.9733rem;
    height: 1.9733rem;
  }
  .comment_img_list {
    width: 100%;
    .img_style {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      margin-right: 0.2667rem;
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
  }
}
</style>
