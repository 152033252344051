<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="contactUs">
    <van-nav-bar
      left-arrow
      :title="titleName"
      @click-left="onClickLeft"
    />
    <div class="customer_service">
      <div class="title">
        <div class="colorLine" />
        <div class="size">
          Customer Service
        </div>
      </div>
      <div class="content">
        <div class="helpEmail">
          <van-icon size="20" name="envelop-o" />
          <div class="emali">
            yaaami.help@gmail.com
          </div>
        </div>
        <div class="help_phone_box">
          <div class="helpPhone">
            <van-icon size="20" name="phone-o" />
            <div class="phone">
              619-693-6666
            </div>
          </div>
          <div class="help_phone_content">
            <div>7 days a week (Monday - Sunday)</div>
            <div>9:00am - 11:00pm ET</div>
            <div>8:00am - 10:00pm CT</div>
            <div>6:00am - 8:00pm PT</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'ContactUs',
  setup() {
    const router = useRouter()
    const state = reactive({
      titleName: 'Contact us'
    })
    onMounted(async() => {

    })
    const onClickLeft = (item) => {
      // 返回
      router.go(-1)
    }
    return {
      ...toRefs(state),
      onClickLeft
    }
  }
}
</script>
<style lang="less">
.contactUs {
  .customer_service{
    width: calc(100% - 40px);
    margin: 10px;
    padding: 10px;
    background: #FFF;
    border-radius: 5px;
    .title{
      display: flex;
      align-items: center;
      .colorLine{
        width: 4px;
        height: 18px;
        background: blue;
        margin-right: 10px;
      }
      .size{
        font-size: 18px;
        font-weight: 600;
      }
    }
    .content{
      .helpEmail{
        display: flex;
        height: 60px;
        align-items: center;
        border-bottom: 1px solid #ddd;
      }
      .emali{
        margin-left: 10px;
        font-size: 16px;
      }
      .help_phone_box{
        .helpPhone{
          display: flex;
          height: 40px;
          align-items: center;
        }
        .phone{
          margin-left: 10px;
          font-size: 16px;
        }
        .help_phone_content{
          div{
            color: #999;
            margin-left: 30px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
