<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="information">
    <van-nav-bar
      title="Message"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <!-- eslint-disable-next-line vue/no-v-model-argument -->
      <van-list v-model:loading="loading" :finished="finished" finished-text="No more" @load="onLoad">
        <van-collapse v-model="activeNames">
          <block v-for="(msg,index) in messageList" :key="msg.id">
            <van-collapse-item :name="index" @click="msg.status === 0 ? setMessageStatus(msg.id) : ''">
              <template #title>
                <div v-if="msg.status === 0" class="line">
                  <img class="envelop-c" src="./../assets/img/envelop-c.png">
                  <div>退款信息</div>
                </div>
                <div v-else class="line">
                  <img class="envelop-o" src="./../assets/img/envelop-o.png">
                  <div>退款信息</div>
                </div>
              </template>
              <div class="message">
                <div class="message_title">
                  <div>时间：{{ msg.created_at }}</div>
                  <div>金额：<i>$</i>{{ $filters.priceHandle(msg.price) }}</div>
                </div>
                {{ msg.comment }}
              </div>
            </van-collapse-item>
          </block>
        </van-collapse>
      </van-list>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getMessage, setMessage } from '@/service/mine'
import { Toast, Notify } from 'vant'

export default {
  name: 'Home',
  setup() {
    const router = useRouter()
    const activeNames = ref(['1'])
    const state = reactive({
      loading: true,
      finished: false,
      pageIndex: 1,
      pageSize: 10,
      messageList: []
    })
    onMounted(async() => {
      getMessageList()
    })
    const onClickLeft = () => {
      // router.push({ path: '/mine' })
      router.go(-1)
    }
    const onLoad = () => {
      if (!state.finished) {
        state.pageIndex++
        state.loading = true
        getMessageList()
      }
    }

    const getMessageList = async() => {
      Toast.loading({
        message: 'loadin...',
        forbidClick: true
      })
      try {
        const res = await getMessage({
          page_index: state.pageIndex,
          page_size: state.pageSize
        })
        if (res.data.code) {
          Notify({
            message: res.data.message,
            color: '#ad0000',
            background: '#ffe1e1'
          })
        } else {
          var list = res.data.data.data
          var total = res.data.data.total
          var newList = state.messageList
          list.forEach(item => {
            if (item) {
              newList.push(item)
            }
          })
          state.messageList = newList
          state.loading = false
          if (total <= newList.length) {
            state.finished = true
          }
        }
      } catch (err) {
        console.log(err)
      }
    }

    const setMessageStatus = async(id) => {
      const res = await setMessage({ id: id })
      if (res.data.code) {
        Notify({
          message: res.data.message,
          color: '#ad0000',
          background: '#ffe1e1'
        })
      } else {
        state.messageList && state.messageList.map(item => {
          if (item.id === res.data.data.id) {
            item.status = 1
          }
          return item
        })
      }
    }

    return {
      ...toRefs(state),
      onClickLeft,
      onLoad,
      activeNames,
      getMessageList,
      setMessageStatus
    }
  }
}
</script>
<style lang="less">
.information {
  .content {
    width: 100%;
    overflow: auto;
    margin-bottom: .4rem /* 30/75 */;
    .van-icon-envelop-o {
      margin-right: 5px;
    }
    .van-badge--dot {
      position:absolute;
    }
    .line{
      display: flex;
      align-items: center;
      .envelop-c {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .envelop-o {
        width: 18px;
        height: 16px;
        margin-right: 7px;
        padding-bottom: 3px;
      }
    }
    .message{
      .message_title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
        font-weight: bold;
        color: #000;
      }
    }
  }
}
</style>
