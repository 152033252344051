const SCOPES = ['snsapi_base', 'snsapi_userinfo']

class VueWechatAuth {
  constructor() {
    this.appid = 'wxd1642056515ff348'
    this.scope = SCOPES[1]
    this._code = ''
    this._redirectUrl = ''
  }

  static makeState() {
    return Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
  }

  setAppId(appid) {
    this.appid = appid
  }

  set redirectUri(redirectUri) {
    console.log(redirectUri)
    this._redirectUrl = encodeURIComponent(redirectUri)
  }

  get redirectUri() {
    return this._redirectUrl
  }

  set state(state) {
    localStorage.setItem('wechat_auth:state', state)
  }

  get state() {
    return localStorage.getItem('wechat_auth:state') || ''
  }

  get authUrl() {
    // eslint-disable-next-line no-throw-literal
    if (!this.appid) throw 'appid must not be empty'
    // eslint-disable-next-line no-throw-literal
    if (!this.redirectUri) throw 'redirect uri must not be empty'
    this.state = VueWechatAuth.makeState()
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.redirectUri}&response_type=code&scope=${this.scope}&state=${this.state}#wechat_redirect`
  }

  get code() {
    // eslint-disable-next-line no-throw-literal
    if (!this._code) throw 'Not get the code from wechat server!'
    const code = this._code
    this._code = ''
    return code
  }
}
const vueWechatAuth = new VueWechatAuth()

export default vueWechatAuth
