<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="order_share">
    <van-nav-bar
      title="Yaaami"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="border_box goods_detail">
        <p class="goods_title title_one">
          Follow your friend to buy
        </p>
        <p class="goods_title title_two">
          You and your friends will get points {{ pointValue }}
        </p>
        <div class="goods_list">
          <div v-for="(item, index) in goodList" :key="index" class="goods_item">
            <img :src="item.pictures" alt="">
          </div>
        </div>
        <div class="count_down">
          <!-- <span>End of distance: </span> -->
          <span>{{ endTime }}</span>
        </div>
      </div>
      <div class="pro_list">
        <div class="goods_list">
          <div v-for="(item,index) in goodList" :key="index" class="goods_item">
            <div class="left" @click="goToDetail(item.id, item.product_stock_id)">
              <img :src="item.pictures" alt="" srcset="">
              <div v-if="item.original_price" class="dis_count">
                {{ (((item.original_price - item.price)/item.original_price)*100).toFixed(0) }}% off
              </div>
            </div>
            <div class="right">
              <p class="name">
                {{ item.name }}
                <span
                  v-if="item.used_storage > 0"
                  class="kucun"
                  :class="item.used_storage< 10?'reduce_storage':''"
                >{{ item.used_storage }} Left</span>
                <span v-else class="no_storage" />
              </p>
              <span class="des">{{ item.desc }}</span>
              <div class="all_price">
                <div>
                  <span class="price">${{ $filters.priceHandle(item.price) }}</span>
                  <span class="o_price">${{ $filters.priceHandle(item.original_price) }}</span>
                </div>
                <div v-if="item.used_storage > 0" class="handle">
                  <van-stepper
                    v-model.number="item.num"
                    theme="round"
                    disable-input
                    :class="item.num>0?'':'no_minus'"
                    :min="0"
                    @plus="plusChange(item,index)"
                    @minus="minusChange(item,index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="add_cart">
        <van-button
          round
          type="primary"
          block
          :color="primaryColor"
          @click="goCart"
        >
          Add to cart and buy
        </van-button>
      </div>
      <div class="share_title">
        These people helped you for rebate
      </div>
      <div class="border_box follow_list">
        <div v-for="(item, index) in buyList" :key="index" class="follow_item">
          <div class="user">
            <div class="img">
              <img :src="item.headSrc" alt="">
            </div>
            <div class="info">
              <span class="name">{{ item.name }}</span>
              <span class="time">{{ item.time }}</span>
            </div>
          </div>
          <div class="status">
            Bought
          </div>
        </div>
        <div v-if="buyList.length == 0" class="no_invite">
          <p>No people yet</p>
          <span class="no_friend"><van-icon name="friends-o" /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Toast, Dialog, Notify } from 'vant'
import { getBuyOrderGoods, getBuyOrderList } from '@/service/point'
import { getMoreGoodsList } from '@/service/product'
import { useStore } from 'vuex'
import { getLocal, setLocal, setLocalCart, delLocalCart } from '@/common/js/utils'
import { addCart, updateCart, delCart } from '@/service/cart'

export default {
  name: 'Home',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      name: '',
      endTime: '',
      linkCode: '',
      pointList: [],
      pointValue: '',
      buyList: [],
      expertTime: '',
      sendCode: '',
      goodList: []
    })
    onMounted(async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const linkCode = route.query.linkCode
      if (linkCode) {
        state.linkCode = linkCode
        setLocal('linkCode', linkCode)
      }
      orderGoodsList()
      orderBuyList()
      await store.dispatch('getPointList')
      state.pointList = store.state.pointList || []
      state.pointList.filter((v) => {
        if (v.user_points_type === 9) {
          state.pointValue = v.count
        }
      })
      Toast.clear()
    })
    const sendEndTime = (nowTime) => {
      state.sendCode = nowTime
      var time = setInterval(() => {
        nowTime--
        state.sendCode = nowTime
        if (nowTime === 0) {
          state.sendCode = 'Link expired'
          clearInterval(time)
        }
      }, 1000)
    }
    const onClickLeft = () => {
      if (state.linkCode) {
        router.push({ path: '/home' })
      } else {
        router.go(-1)
      }
    }
    const goCart = async() => {
      router.push({ path: `/cart` })
    }
    const orderBuyList = async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      try {
        const res = await getBuyOrderList({
          link_code: state.linkCode,
          points_type: 2
        })
        if (res.data.code) {
          Toast.clear()
          Notify({ type: 'danger', message: res.data.message })
        } else {
          const list = res.data.data
          var newLists = []
          list.forEach(element => {
            const num = Math.floor(Math.random() * 8)
            var obj = {}
            obj.headSrc = require('./../assets/img/head/head' + num + '.png')
            obj.name = element.promote.user_name
            obj.created_at = element.promote.created_at
            newLists.push(obj)
            // item.user_show_name = item.user_show_name.substring(0,3)+"****"+item.user_show_name.substring(7,item.user_show_name.length)
          })
          state.buyList = newLists
          Toast.clear()
        }
      } catch (err) {
        Toast.clear()
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    const orderGoodsList = async() => {
      try {
        const res = await getBuyOrderGoods({
          link_code: state.linkCode
        })
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
        } else {
          const data = res.data.data
          const list = []
          var sale_time = ''
          data.order.forEach(item => {
            list.push(item.product_id)
            sale_time = item.sale_time
          })
          var idStr = list.join(',')
          state.expertTime = data.time_expired
          countdown()
          getGoodsDetail(idStr, sale_time)
        }
      } catch (err) {
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    const getGoodsDetail = async(idStr, sale_time) => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const res = await getMoreGoodsList({
        id_str: idStr,
        sale_time: ''
        // sale_time: sale_time
      })
      const goodList = res.data.data
      goodList.forEach(item => {
        item.num = 0
      })
      state.goodList = goodList
      Toast.clear()
    }
    const plusChange = async(item, index) => {
      var stock_id = item.product_stock_id
      var goods_id = item.id
      var count = Number(item.num) + 1
      const userId = getLocal('userId')
      if (userId) {
        const res = await addCart({
          stock_id: stock_id,
          count: count
        })
        if (res.data.code) {
          if (res.data.code === 130048) {
            Dialog.confirm({
              title: 'Warning',
              message: 'The delivery date of this product is different from other products in the shopping cart. Please clear or checkout shopping cart first.'
            }).then(async() => {
              router.push({ path: `/cart` })
            }).catch(() => {
              // on cancel
              item.num = 0
            })
          } else {
            item.num = 0
            Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
          }
        }
        store.dispatch('updateCart')
      } else {
        const saveTemp = setLocalCart(goods_id, count, stock_id, item.sale_time)
        if (saveTemp) {
          store.dispatch('updateLocalCart')
        } else {
          Dialog.confirm({
            title: 'Warning',
            message: 'The delivery date of this product is different from other products in the shopping cart. Please clear or checkout shopping cart first.'
          }).then(async() => {
            router.push({ path: `/cart` })
          }).catch(() => {
            item.num = 0
          })
        }
      }
    }
    const minusChange = async(item, index) => {
      var goods_id = item.id
      var stock_id = item.product_stock_id
      var count = Number(item.num) - 1
      const userId = getLocal('userId')
      if (userId) {
        if (count > 0) {
          await updateCart({
            stock_id: stock_id,
            count: count
          })
        } else {
          await delCart({
            id_list: [stock_id]
          })
        }
        store.dispatch('updateCart')
      } else {
        if (count > 0) {
          setLocalCart(goods_id, count, stock_id, item.sale_time)
        } else {
          delLocalCart(goods_id, stock_id)
        }
        store.dispatch('updateLocalCart')
      }
    }
    const countdown = () => {
      // 目标日期时间戳
      const end = Date.parse(new Date(state.expertTime))
      // 当前时间戳
      const now = Date.parse(new Date())
      // 相差的毫秒数
      const msec = end - now
      if (msec <= 0) {
        state.endTime = 'Link expired'
        return false
      }
      // 计算时分秒数
      const day = parseInt(msec / 1000 / 60 / 60 / 24)
      let hr = parseInt(msec / 1000 / 60 / 60 % 24)
      let min = parseInt(msec / 1000 / 60 % 60)
      let sec = parseInt(msec / 1000 % 60)
      // 个位数前补零
      hr = hr > 9 ? hr : '0' + hr
      min = min > 9 ? min : '0' + min
      sec = sec > 9 ? sec : '0' + sec
      // 控制台打印
      state.endTime = `${day}day ${hr}hour ${min}min ${sec}sec`
      // 一秒后递归
      setTimeout(() => {
        countdown()
      }, 1000)
    }

    const goToDetail = (id, stock_id) => {
      router.push({
        path: '/goodsDetail',
        query: { id: id, stock_id: stock_id }
      })
    }

    return {
      ...toRefs(state),
      orderGoodsList,
      onClickLeft,
      orderBuyList,
      goCart,
      sendEndTime,
      getGoodsDetail,
      plusChange,
      minusChange,
      countdown,
      goToDetail
    }
  }
}
</script>
<style lang="less">
.order_share {
  .content {
    width: 100%;
    overflow: auto;
    // height: calc(100vh - 96px);
    margin-bottom: .4rem /* 30/75 */;
    .border_box {
      width: calc(100% - 0.5333rem);
      margin: 0.2667rem auto;
      overflow: auto;
      background: #fff;
      padding: 0.2667rem;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .goods_detail {
      .goods_title {
        font-size: 0.4267rem;
        text-align: center;
        font-weight: 500;
      }
      .title_one {
        color: #666;
      }
      .title_two {
        color: #eb5a03;
        font-size: 0.48rem;
      }
      .count_down {
        width: 100%;
        text-align: center;
        font-size: 0.4267rem;
        color: red;
        font-weight: 500;
        margin: 0.2667rem 0;
      }
      .goods_list {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        // border-bottom: 1px solid #f4f5f7;
        max-height: 5.3333rem;
        overflow-y: auto;
        .goods_item {
          width: 2.1rem;
          height: 2.1rem;
          margin: 0.2667rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .add_cart {
      width: calc(100% - 0.5333rem);
      margin: 0.2667rem auto;
      .van-button--normal {
        font-size: 0.4267rem;
        font-weight: bolder;
      }
    }
    .pro_list {
      width: calc(100% - 0.5333rem);
      margin: 0.2667rem auto;
      overflow: auto;
      background: #fff;
      padding: 0.2667rem;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .share_title {
      width: 100%;
      height: 1rem;
      line-height: 1rem;
      padding: 0 0.4rem;
      font-size: 0.4267rem;
      font-weight: 500;
      box-sizing: border-box;
    }
    .follow_list {
      box-sizing: border-box;
      // padding: 0 0.4rem 0.4rem;
      background: #fff;
      .follow_item {
        width: 100%;
        height: 1.28rem;
        border-bottom: 1px solid #f4f5f7;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.15rem 0;
        .user {
          display: flex;
          flex-direction: row;
          align-items: center;
          .img {
            margin-right: 0.2667rem;
          }
          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 0.3733rem;
            .name {
              margin-bottom: 0.1rem;
              color: #333;
            }
            .time {
              color: #999;
            }
          }
        }
        &:last-child {
          border-bottom: none;
        }
        .status {
          height: 24px;
          padding: 0.1rem 0.2667rem;
          text-align: center;
          background: #FFF5E8;
          border-radius: 5px;
          font-weight: 500;
          font-size: 15px;
          color: #DF2C2E;
        }
      }
    }
    .no_invite {
      width: 100%;
      height: 3.6667rem;
      p {
        width: 100%;
        font-size: 0.4267rem;
        color: #666;
        text-align: center;
      }
      span {
        font-size: 2rem;
        text-align: center;
        display: block;
        color: #666;
      }
    }
  }
}
</style>
