<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="card_bag">
    <van-nav-bar
      title="Card bag"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="card_list">
      <div class="card_title">
        <p>The billing info</p>
        <span><van-button size="small" round :color="primaryColor" @click="onClickRight">Add card</van-button></span>
      </div>
      <div class="card_header col">
        <span><b>Number</b></span>
        <span><b>Brand</b></span>
        <span><b>Exp</b></span>
      </div>
      <div v-if="cardList.length>0">
        <div v-for="(item,index) in cardList" :key="index" class="card_body col">
          <span>****{{ item.last4 }}</span>
          <span>{{ item.brand }}</span>
          <span>
            {{ item.exp_month }}/{{ item.exp_year }}
          </span>
        </div>
      </div>
      <div v-else class="no_data">
        No data
      </div>
    </div>
    <add-card
      :add-dialog-show="addDialogShow"
      @closeAddDialog="closeAddDialog"
      @addCardSuccess="addCardSuccess"
    />
  </div>
</template>

<script>
// @ is an alias to /src
// import { getLocal } from '@/common/js/utils'
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import AddCard from '../components/AddCard.vue'
import { stripePay } from '../mixin/stripePay.js'

export default {
  name: 'Home',
  components: {
    AddCard
  },
  mixins: [stripePay],
  setup() {
    const router = useRouter()
    const state = reactive({
      cardList: [],
      addDialogShow: false
    })
    onMounted(() => {
      getCards()
    })
    const getCards = async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const customer = await stripePay.methods.getCustomer()
      const payMethods = await stripePay.methods.getPayMethods(customer.id)
      state.cardList = payMethods.map(v => {
        return v.card
      }) || []
    }
    const onClickLeft = (item) => {
      router.go(-1)
    }
    const onClickRight = () => {
      state.addDialogShow = true
    }
    const closeAddDialog = () => {
      state.addDialogShow = false
    }
    const addCardSuccess = () => {
      getCards()
      closeAddDialog()
    }
    return {
      ...toRefs(state),
      onClickRight,
      closeAddDialog,
      onClickLeft,
      getCards,
      addCardSuccess
    }
  }
}
</script>
<style lang="less">
.card_bag {
  .van-nav-bar__right {
    .van-nav-bar__text {
      color: #ee0a24;
      font-weight: 500;
    }
  }
  .card_title {
    width: calc(100% - 0.5333rem);
    display: flex;
    justify-content: space-between;
    margin: 0 0.2667rem;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 0.2667rem;
    p {
      font-weight: 500;
    }
  }
  .card_list {
    width: calc(100% - 0.5333rem);
    margin: 0.2667rem;
    background: #fff;
    box-sizing: border-box;
    border: 1px solid #ebeef5;
    font-size: 0.4267rem;
    border-radius: 0.1333rem;
    padding-bottom:  0.2667rem;
    .col {
      width: 100%;
      height: 1.0667rem;
      line-height: 1.0667rem;
      padding: 0 0.2667rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      border-bottom: 1px solid #ebeef5;
      span {
        flex: 1;
        text-align: center;
      }
      &:nth-child(odd) {
        background: #fafafa;
      }
    }
  }
}
</style>
