<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 17:40:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\About.vue
-->
<template>
  <div class="login">
    <van-nav-bar
      title="Yaaami"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="title">
      Login user
    </div>
    <div class="login_info">
      <van-field v-model="phone" type="tel" placeholder="4081118888" label="Phone" />
      <van-field
        v-model="sms"
        center
        clearable
        label="Verification code"
        placeholder="123456"
      >
        <template #button>
          <van-button
            :color="gradientColor"
            size="small"
            round
            type="primary"
            :class="sended?'sended':''"
            @click="sendCodeFun"
          >
            {{ sendCode }}
          </van-button>
        </template>
      </van-field>
    </div>
    <div class="login_btn">
      <van-button
        type="primary"
        :color="gradientColor"
        round
        size="large"
        :loading="loadingLogin"
        @click="goLogin"
      >
        Login
      </van-button>
    </div>
    <van-divider>Or</van-divider>
    <div class="third_login">
      <div class="login_fun" @click="goGoogle">
        <div class="icon google_login" />
        <p>Google</p>
      </div>
      <div class="login_fun" @click="goFacebook" v-if="false">
        <div class="icon face_login" />
        <p>Facebook</p>
      </div>
      <!-- <div class="login_fun" @click="goWechat">
        <div class="icon wx_login"></div>
        <p>Wechat</p>
      </div> -->
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Notify } from 'vant'
import { getsms, login, wxLogin } from '@/service/user'
import { setLocal, handleLocalCart, getLocal, processUrl } from '@/common/js/utils'
import vueWechatAuth from '@/plugins/vueWechatAuth'

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      phone: '',
      sms: '',
      sendCode: 'Send code',
      sended: false,
      loadingLogin: false,
      linkCode: ''
    })
    onMounted(() => {
      const code = route.query.code
      const linkCode = route.query.linkCode
      if (linkCode) {
        state.linkCode = linkCode
        setLocal('linkCode', linkCode)
      }
      if (code) {
        console.log(code)
        goWechatLogin(code)
      }
      init()
    })
    // 返回
    const onClickLeft = () => {
      router.push({ path: '/home' })
      // router.go(-1)
    }
    const sendCodeFun = async() => {
      if (!state.phone) {
        Notify({
          message: 'Please input mobile!',
          color: '#ad0000',
          background: '#ffe1e1'
        })
        return false
      }
      if (!state.sended) {
        // 请求获取验证码
        const res = await getsms({ phone: state.phone })
        if (res.data.code) {
          Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
        } else {
          sendYzm()
        }
      }
    }
    const sendYzm = () => {
      var nowTime = 60
      state.sendCode = nowTime + 's again'
      state.sended = true
      var time = setInterval(() => {
        nowTime--
        state.sendCode = nowTime + 's again'
        if (nowTime === 0) {
          state.sendCode = 'Resend'
          state.sended = false
          clearInterval(time)
        }
      }, 1000)
    }
    const goLogin = async() => {
      if (!state.phone || !state.sms) {
        Notify({
          message: 'Please complete the information！',
          color: '#ad0000',
          background: '#ffe1e1'
        })
        return false
      }
      state.loadingLogin = true
      // 请求登录接口
      var params = {}
      var linkCode = getLocal('linkCode')
      if (window.location.host === 'yaaami.com') {
        // 正式环境
        params = { phone: state.phone, code: state.sms, link_code: linkCode }
      } else {
        params = { phone: state.phone, code: state.sms, link_code: linkCode, debug: 1 }
      }
      const res = await login(params)
      if (res.data.code) {
        state.loadingLogin = false
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        state.loadingLogin = false
        localStorage.setItem('loginInfo', JSON.stringify(res.data.data))
        setLocal('userId', res.data.data.user_id)
        setLocal('userName', res.data.data.name)
        sessionStorage.setItem('customer', '')
        handleLocalCart()
        onClickLeft()
      }
    }
    const init = async() => {

    }
    const goGoogle = async() => {
      var linkCode = getLocal('linkCode')
      if (window.location.host === 'yaaami.com') {
        window.location.href = 'https://yaaami.com/api/GoogleLogin?linkCode=' + linkCode
      } else {
        window.location.href = 'http://test.yaaami.com/api/GoogleLogin?linkCode=' + linkCode
      }
    }
    const goFacebook = async() => {
      var linkCode = getLocal('linkCode')
      if (window.location.host === 'yaaami.com') {
        window.location.href = 'https://yaaami.com/api/fbAuth?linkCode=' + linkCode
      } else {
        window.location.href = 'http://test.yaaami.com/api/fbAuth?linkCode=' + linkCode
      }
    }

    const goWechat = async() => {
      // if (window.location.host == 'yaaami.com') {
      //   window.location.href = 'https://yaaami.com/api/fbAuth'
      // } else {
      console.log(processUrl())
      vueWechatAuth.redirectUri = processUrl()
      console.log(vueWechatAuth.authUrl)
      window.location.href = vueWechatAuth.authUrl
      // }
    }

    const goWechatLogin = async(auth_code) => {
      state.loadingLogin = true
      try {
        const res = await wxLogin({ auth_code })
        if (res.data.code) {
          state.loadingLogin = false
          Notify({ type: 'danger', message: res.data.message })
        } else {
          state.loadingLogin = false
          localStorage.setItem('loginInfo', JSON.stringify(res.data.data))
          setLocal('userId', res.data.data.user_id)
          setLocal('userName', res.data.data.name)
          sessionStorage.setItem('customer', '')
          router.push({ path: '/home' })
        }
      } catch (err) {
        state.loadingLogin = false
        Notify({ type: 'danger', message: 'Server error' })
      }
    }

    return {
      ...toRefs(state),
      onClickLeft,
      sendCodeFun,
      goLogin,
      goGoogle,
      goFacebook,
      goWechat
    }
  }
}
</script>
<style lang="less">
@import '../common/style/mixin';
.login {
  background: #fff;
  .title {
    width: 100%;
    padding:1rem 0.4rem;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 500;
  }
  .login_btn {
    width: 100%;
    box-sizing: border-box;
    padding: 0.4rem;
    padding-bottom: 0.5333rem;
  }
  .sended {
    background: #ccc !important;
    border-color: #ccc !important;
  }
  .van-button {
    font-size: 0.48rem;
  }
  .van-button--small {
    font-size: 0.4rem;
  }
  .third_login {
    width: 100%;
    height: 3rem;
    padding: 0.4rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .icon {
      width: 1.28rem;
      height: 1.28rem;
      border-radius: 50%;
    }
    p {
      font-size: 0.4rem;
      text-align: center;
      color: gray;
    }
    .google_login {
      background: #4285F4 url('./../assets/img/google.png') no-repeat center;
      background-size: 60%;
    }
    .face_login {
      background: #3b5998 url('./../assets/img/facebook.png') no-repeat center;
      background-size: 60%;
    }
    .wx_login {
      background: #04BE02 url('./../assets/img/weixin.png') no-repeat center;
      background-size: 60%;
    }
    .login_fun {
      width: 2rem;
      height: 3rem;
      margin: 0 0.6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
