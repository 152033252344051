<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="add_address">
    <van-nav-bar
      title="Edit delivery address"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="van-address-edit__fields">
        <van-cell-group>
          <van-field v-model="addressInfo.phone" label="Phone" placeholder="1234567" />
          <van-field v-model="addressInfo.receiver_name" label="Name" placeholder="name" />
          <van-field v-model="addressInfo.email" label="Email" placeholder="email" />
          <div class="van-cell van-field">
            <div class="van-cell__title van-field__label">
              <span>Address</span>
            </div>
            <div class="van-cell__value van-field__value">
              <div class="van-field__body">
                <!-- eslint-disable-next-line vue/html-self-closing -->
                <input id="senderAddress" v-model="addressInfo.address_detail" type="text" class="autocompleteFiled van-field__control" placeholder="address" />
              </div>
            </div>
          </div>
          <van-field v-model="addressInfo.room" label="Room#" placeholder="Room#" />
          <div class="radio_checked">
            <div class="radio_label">
              This is
            </div>
            <van-radio-group v-model="apartChecked" direction="horizontal">
              <van-radio :name="1">
                Apartment
              </van-radio>
              <van-radio :name="2">
                No apartment
              </van-radio>
            </van-radio-group>
          </div>
          <van-field
            v-if="apartChecked == 1"
            v-model="addressInfo.house"
            label=""
            placeholder="apartment"
          />
          <div v-if="apartChecked == 1" class="radio_checked">
            <div class="radio_label label_margin">
              Is there gate code to reach this address?
            </div>
            <van-radio-group v-model="gateChecked" direction="horizontal">
              <van-radio :name="1">
                Yes
              </van-radio>
              <van-radio :name="2">
                No
              </van-radio>
            </van-radio-group>
          </div>
          <van-field
            v-if="gateChecked == 1"
            v-model="addressInfo.gate_number"
            label=""
            placeholder="Gate code #"
          />
        </van-cell-group>
      </div>
      <div class="van-address-edit__buttons">
        <van-button round type="danger" block @click="goReq(1)">
          Edit order
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { getLocal } from '@/common/js/utils'
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, Notify } from 'vant'
import { editOrderAddress } from '@/service/user'
import { Loader } from '@googlemaps/js-api-loader'
const loader = new Loader({
  apiKey: 'AIzaSyDR0LyFODkJ8CS3B8bGO_YoCzK3C279O90',
  version: 'weekly',
  libraries: ['places']
})

export default {
  name: 'Home',
  setup() {
    const router = useRouter()
    const state = reactive({
      addressInfo: {
        phone: '',
        receiver_name: '',
        email: '',
        code: '',
        address_detail: '',
        room: '',
        house: '',
        gate_number: ''
      },
      checked: false,
      isEdit: false,
      apartChecked: 1,
      gateChecked: 1,
      editId: ''
    })
    onMounted(() => {
      state.addressInfo = JSON.parse(sessionStorage.getItem('orderInfo'))
      console.log(state.addressInfo)
      init()
    })
    // 返回
    const init = async() => {
      loader.load().then(() => {
        const senderAddressEle = document.getElementById('senderAddress')
        // eslint-disable-next-line no-undef
        const autosenderAddress = new google.maps.places.Autocomplete(senderAddressEle)
        autosenderAddress.addListener('place_changed', () => {
          const senderAddress = autosenderAddress.getPlace()
          state.addressInfo.address_detail = senderAddress.formatted_address
          if (!senderAddress.geometry) {
            Notify({ type: 'danger', message: 'Please enter the specific address' })
          }
        })
      })
    }
    const onClickLeft = () => {
      router.go(-1)
    }
    const goReq = (type) => {
      // type =1 add; =2 edit
      if (!state.addressInfo.phone || !state.addressInfo.email || !state.addressInfo.receiver_name || !state.addressInfo.address_detail) {
        Notify({ message: 'Please complete the information', color: '#ad0000', background: '#ffe1e1' })
        return false
      }
      if (state.checked) {
        state.addressInfo.status = 1
      } else {
        state.addressInfo.status = 0
      }
      if (state.apartChecked === 1 && !state.addressInfo.house) {
        Notify({ message: 'Please input apartment', color: '#ad0000', background: '#ffe1e1' })
        return false
      }
      if (state.gateChecked === 1 && !state.addressInfo.gate_number) {
        Notify({ message: 'Please input gate', color: '#ad0000', background: '#ffe1e1' })
        return false
      }
      if (state.apartChecked === 2) {
        state.addressInfo.house = ''
      }
      if (state.gateChecked === 2) {
        state.addressInfo.gate_number = ''
      }
      goAdd()
    }
    const goAdd = async() => {
      const res = await editOrderAddress(state.addressInfo)
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        Toast.success('Add success')
        onClickLeft()
      }
    }
    return {
      ...toRefs(state),
      init,
      onClickLeft,
      goReq,
      goAdd
    }
  }
}
</script>
<style lang="less">
@import '../common/style/mixin';
.add_address {
  .content {
    width: 100%;
    padding: 0.2667rem;
    box-sizing: border-box;
    .van-button--danger {
      background-color: @primary;
      border: 1px solid @primary;
    }
    .del {
      border: 1px solid #ebedf0;
      color: #333;
    }
    .radio_checked {
      width: 100%;
      padding: 10px 16px;
      box-sizing: border-box;
      font-size: 0.4267rem;
      display: flex;
      flex-wrap: wrap;
      .radio_label {
        margin-right: 10px;
      }
      .label_margin {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
