<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="order_share">
    <van-nav-bar
      title="Yaaami points"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="top_style">
        <div class="left">
          Total points: {{ totalPoints }}
        </div>
        <div class="right">
          Equal to ${{ $filters.priceHandle(totalPoints) }}
        </div>
      </div>
      <!--  eslint-disable-next-line vue/no-v-model-argument -->
      <van-tabs v-model:active="activeName" class="card_tag" @change="changeTag">
        <van-tab title="All" />
        <van-tab title="Obtain" />
        <van-tab title="Consume" />
      </van-tabs>
      <div class="border_box">
        <div class="point_list">
          <!--  eslint-disable-next-line vue/no-v-model-argument -->
          <van-list v-model:loading="loading" :finished="finished" finished-text="No more" @load="onLoad">
            <div v-for="(item, index) in pointerDetailList" :key="index" class="point_item">
              <div class="user">
                <div v-if="item.action == 1" class="img">
                  <img src="./../assets/img/point/get.png" alt="">
                </div>
                <div v-else class="img">
                  <img src="./../assets/img/point/set.png" alt="">
                </div>
                <div class="info">
                  <span class="name">{{ item.reasonMsg }}</span>
                  <span class="time">{{ item.createdAt }}</span>
                </div>
              </div>
              <div v-if="item.action == 1" class="status">
                +{{ item.amount }}
              </div>
              <div v-else class="status">
                {{ item.amount }}
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, Notify } from 'vant'
import { pointDetailed } from '@/service/point'
import { getTotalPoint } from '@/service/point'

export default {
  name: 'Home',
  setup() {
    const router = useRouter()
    const state = reactive({
      activeName: 0,
      pageIndex: 0,
      pageSize: 50,
      loading: true,
      finished: false,
      pointerDetailList: [],
      totalPoints: 101
    })
    onMounted(async() => {
      totalPoint()
    })
    const onClickLeft = () => {
      // router.push({ path: '/mine'})
      router.go(-1)
    }
    const changeTag = (val) => {
      state.pageIndex = 1
      state.pointerDetailList = []
      getPointDetail()
    }
    const onLoad = () => {
      if (!state.finished) {
        state.pageIndex++
        state.loading = true
        getPointDetail()
      }
    }

    const totalPoint = async() => {
      const res = await getTotalPoint({})
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        const obj = res.data.data
        state.totalPoints = obj.available_points
      }
    }

    const getPointDetail = async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const res = await pointDetailed({ action: state.activeName, page: state.pageIndex, pageSize: state.pageSize })
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
        Toast.clear()
      } else {
        Toast.clear()
        const list = res.data.data.list
        var total = res.data.data.total
        var newList = state.pointerDetailList
        list.forEach(item => {
          newList.push(item)
        })
        state.pointerDetailList = newList
        state.loading = false
        if (total <= newList.length) {
          state.finished = true
        }
      }
    }
    return {
      ...toRefs(state),
      onClickLeft,
      changeTag,
      onLoad,
      getPointDetail
    }
  }
}
</script>
<style lang="less">
.order_share {
  .content {
    width: 100%;
    overflow: auto;
    margin-bottom: .4rem /* 30/75 */;
    .top_style {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 1.2rem;
      line-height: 1.2rem;
      padding: 0 0.2667rem;
      box-sizing: border-box;
      background: #fff;
      border-bottom: 1px solid #eee;
      div {
        flex: 1;
        font-size: 0.3733rem;
        color: #333;
        text-align: center;
        font-weight: 500;
      }
    }
    .border_box {
      width: calc(100% - 0.5333rem);
      margin: 0.2667rem auto;
      overflow: auto;
      background: #fff;
      padding: 0.2667rem;
      box-sizing: border-box;
      padding-top: 0.4rem;
      border-radius: 5px;
      .point_item {
        width: 100%;
        height: 1.28rem;
        border-bottom: 1px solid #f4f5f7;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.15rem 0;
        .user {
          display: flex;
          flex-direction: row;
          align-items: center;
          .img {
            margin-right: 0.2667rem;
            width: 0.7rem;
            img {
              width: 100%;
            }
          }
          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 0.3733rem;
            .name {
              margin-bottom: 0.1rem;
              color: #333;
            }
            .time {
              color: #999;
            }
          }
        }
        &:last-child {
          border-bottom: none;
        }
        .status {
          height: 24px;
          padding: 0.1rem 0.2667rem;
          text-align: center;
          background: #FFF5E8;
          border-radius: 5px;
          font-weight: 500;
          font-size: 15px;
          color: #DF2C2E;
        }
      }
    }
  }
}
</style>
