<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="address_list">
    <van-nav-bar title="Address list" left-arrow @click-left="onClickLeft" />
    <van-address-list
      v-model="chosenAddressId"
      :class="source ? '' : 'no_radio'"
      :list="addressList"
      default-tag-text="Default"
      @add="onAdd"
      @edit="onEdit"
      @select="onSelect"
      @address-list-item-radio-icon-color="primaryColor"
    />
    <div v-if="addressList.length == 0" class="no_data">No-data</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Toast, Notify } from "vant";
import { getAddressList } from "@/service/user";

export default {
  name: "Home",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      chosenAddressId: 0,
      addressList: [],
      source: "",
    });
    onMounted(async () => {
      state.source = route.query.source;
      if (state.source) {
        state.chosenAddressId = Number(route.query.id);
      }
      getAddressData();
    });
    const onClickLeft = (item) => {
      router.go(-1);
    };
    const onAdd = (item) => {
      router.push({ path: `/addAddress` });
    };
    const onEdit = (item) => {
      sessionStorage.setItem("addressInfo", JSON.stringify(item));
      router.push({
        path: "/addAddress",
        query: {
          id: item.id,
        },
      });
    };
    const onSelect = (item) => {
      if (state.source) {
        router.push({
          path: "/submitOrder",
          query: {
            addressId: item.id,
          },
        });
      }
    };
    const getAddressData = async () => {
      try {
        Toast.loading({
          message: "loading...",
          forbidClick: true,
        });
        const res = await getAddressList({ address_id: 0 });
        if (res.data.code) {
          Notify({
            message: res.data.message,
            color: "#ad0000",
            background: "#ffe1e1",
          });
        } else {
          const list = res.data.data;
          list.forEach((item) => {
            item.name = item.receiver_name;
            item.tel = item.phone;
            var apartment = item.house ? ", Apartment:" + item.house : "";
            var gate = item.gate_number
              ? ", Gate code:" + item.gate_number
              : "";
            item.address = item.address_detail + item.room + apartment + gate;
            item.isDefault = !!item.status;
          });
          state.addressList = list;
        }
        Toast.clear();
      } catch (err) {
        console.log(err);
      }
    };
    return {
      ...toRefs(state),
      onAdd,
      onEdit,
      onSelect,
      onClickLeft,
      getAddressData,
    };
  },
};
</script>
<style lang="less">
@import "../common/style/mixin";
.address_list {
  .van-address-list__bottom {
    .van-button--danger {
      background-color: @primary;
      border: 1px solid @primary;
    }
  }
  .van-address-item .van-radio__icon--checked .van-icon {
    background-color: @primary;
    border: 1px solid @primary;
  }
  .content {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 96px);
    margin-bottom: 0.4rem /* 30/75 */;
  }
  .van-address-item__name {
    height: 0.6667rem;
    font-size: 0.4267rem;
  }
  .van-address-item__address {
    font-size: 0.4rem;
    line-height: 0.48rem;
  }
  .no_radio {
    .van-radio {
      cursor: initial;
    }

    .van-radio__icon {
      display: none;
    }
  }
}
</style>
