<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="register_share">
    <van-nav-bar
      title="Yaaami"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="banner_box" />
      <div class="user_info">
        <div class="box left">
          <p>{{ inviteObj.reached_user_num }}</p>
          <span>Friends invited</span>
        </div>
        <div class="box right">
          <p>{{ inviteObj.total_points }}</p>
          <span>Rewards earned</span>
        </div>
      </div>
      <p class="rules_detail">
        Earn points for every friend you invite. They get <span class="h">{{ pointValue }}
        </span> off their first purchase while you earn <span class="h">{{ pointValue }}</span>
      </p>
      <div class="share_btn">
        <van-button
          class="go_share_btn"
          block
          round
          color="linear-gradient(to right, #43c6ac, #1d953f)"
          @click="goShare"
        >
          Refer a friend
        </van-button>
      </div>
      <div class="border_box invited_list">
        <div class="invite_title">
          Invited Friends
        </div>
        <div v-for="(item, index) in pointDetailList" :key="index" class="invited_item">
          <div class="user">
            <div class="img">
              <!--  eslint-disable-next-line vue/html-self-closing -->
              <img :src="item.headSrc" />
            </div>
            <div class="info">
              <span class="name">{{ item.CreatedAt }}</span>
              <span class="time">{{ item.createdAt }}</span>
            </div>
          </div>
          <div class="status">
            +{{ item.amount }}
          </div>
        </div>
        <div v-if="pointDetailList.length == 0" class="no_invite">
          <p>You have not invited any friends at this time</p>
          <span class="no_friend"><van-icon name="friends-o" /></span>
        </div>
      </div>
    </div>
    <!--  eslint-disable-next-line vue/no-v-model-argument -->
    <van-share-sheet v-model:show="showShare" title="Share it with friends now" :options="shareOptions" @select="onSelectShare" />
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, Notify } from 'vant'
import { shareInfo, getSharePoint, pointDetailed } from '@/service/point'
import { useStore } from 'vuex'

export default {
  name: 'Home',
  setup() {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      showShare: false,
      shareOptions: [
        {
          name: 'Facebook',
          icon: require('./../assets/img/fb.png')
        },
        {
          name: 'Copy url',
          icon: 'link'
        }
      ],
      linkCode: '',
      pointList: [],
      pointValue: '',
      inviteObj: {},
      pointDetailList: []
    })
    onMounted(async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      getShareInfo()
      await store.dispatch('getPointList')
      state.pointList = store.state.pointList || []
      state.pointList.filter((v) => {
        if (v.user_points_type === 3) {
          state.pointValue = v.count
        }
      })
      Toast.clear()
    })
    const onClickLeft = () => {
      router.push({ path: '/home' })
    }
    const goShare = () => {
      state.showShare = true
    }
    const getInviteInfo = async() => {
      try {
        const res = await getSharePoint({
          source_id: state.linkCode,
          reason: 5
          // linkcode: state.linkCode
        })
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
        } else {
          const obj = res.data.data
          state.inviteObj = obj
        }
      } catch (err) {
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    const getInviteList = async() => {
      try {
        const res = await pointDetailed({
          source_id: state.linkCode,
          reason: 5,
          pageSize: 1000
        })
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
        } else {
          const list = res.data.data.list
          list.forEach(element => {
            const num = Math.floor(Math.random() * 8)
            element.headSrc = require('./../assets/img/head/head' + num + '.png')
            // item.user_show_name = item.user_show_name.substring(0,3)+"****"+item.user_show_name.substring(7,item.user_show_name.length)
          })
          state.pointDetailList = list
        }
      } catch (err) {
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    const getShareInfo = async() => {
      try {
        // 跟买 2 分享 1
        const res = await shareInfo({
          promote_type: 1
        })
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
        } else {
          const obj = res.data.data
          state.linkCode = obj.link
          getInviteInfo()
          getInviteList()
        }
      } catch (err) {
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    const onSelectShare = (item) => {
      const name = item.name
      let url = ''; let appId = ''
      if (window.location.host === 'yaaami.com') {
        url = 'https://yaaami.com/login?linkCode=' + state.linkCode
        appId = '288178443052249'
      } else {
        url = 'http://test.yaaami.com/login?linkCode=' + state.linkCode
        appId = '227444888941530'
      }
      if (name === 'Facebook') {
        // 线上：288178443052249
        // 测试：227444888941530
        // eslint-disable-next-line no-undef
        FB.init({
          appId: appId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v2.12'
        })
        // eslint-disable-next-line no-undef
        FB.ui(
          {
            method: 'share',
            mobile_iframe: true,
            href: url
          },
          function(response) {}
        )
      } else {
        var oInput = document.createElement('input')
        oInput.value = url
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        Toast('Copy success！')
      }
    }
    return {
      ...toRefs(state),
      onClickLeft,
      goShare,
      onSelectShare,
      getShareInfo,
      getInviteInfo,
      getInviteList
    }
  }
}
</script>
<style lang="less">
.register_share {
  .content {
    width: 100%;
    overflow: auto;
    margin-bottom: .4rem /* 30/75 */;
    .border_box {
      width: calc(100% - 0.5333rem);
      margin: 0.2667rem auto;
      overflow: auto;
      background: #fff;
      padding: 0.2667rem;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .banner_box {
      width: calc(100% - 0.5333rem);
      margin: 0.2667rem auto 0.2667rem;
      height: 4.2rem;
      background: url('./../assets/img/share_banner.png') no-repeat center;
      background-size: 100%;
      border-radius: 0.2667rem;
    }
    .user_info {
      padding: 0 0.2667rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      .box {
        width: 49%;
        background:  #fff;
        box-sizing: border-box;
        border-radius: 0.2667rem;
        padding: 0.3rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-around;
        align-items: center;
        p {
          margin: auto;
          font-size: 0.48rem;
          color: red;
          font-weight: bolder;
          margin-bottom: 0.2rem;
        }
        span {
          font-size: 0.4267rem;
        }
      }
    }
    .rules_detail {
      width: 100%;
      padding: 0.2667rem;
      box-sizing: border-box;
      line-height: 0.6rem;
      font-size: 0.3733rem;
      margin: 0;
      .h {
        font-weight: 700;
      }
    }
    .share_btn {
      width: 100%;
      padding: 0.2667rem;
      box-sizing: border-box;
      line-height: 0.6rem;
      font-size: 0.3733rem;
      .go_share_btn {
        font-weight: bolder;
      }
    }
    .invited_list {
      .invite_title {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        font-size: 0.4267rem;
        font-weight: 500;
        padding-bottom: 0.2667rem;
        border-bottom: 1px solid #f4f5f7;
      }
      .no_invite {
        width: 100%;
        height: 3.6667rem;
        p {
          width: 100%;
          font-size: 0.4267rem;
          color: #666;
          text-align: center;
        }
        span {
          font-size: 2rem;
          text-align: center;
          display: block;
          color: #666;
        }
      }
      .invited_item {
        width: 100%;
        height: 1.28rem;
        border-bottom: 1px solid #f4f5f7;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.15rem 0;
        .user {
          display: flex;
          flex-direction: row;
          align-items: center;
          .img {
            margin-right: 0.2667rem;
          }
          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 0.3733rem;
            .name {
              margin-bottom: 0.1rem;
              color: #333;
            }
            .time {
              color: #999;
            }
          }
        }
        &:last-child {
          border-bottom: none;
        }
        .status {
          height: 24px;
          padding: 0.1rem 0.2667rem;
          text-align: center;
          background: #FFF5E8;
          border-radius: 5px;
          font-weight: 500;
          font-size: 15px;
          color: #DF2C2E;
        }
      }
    }
  }
}
</style>
