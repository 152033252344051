/* eslint-disable vue/no-v-model-argument */
<template>
  <div class="product">
    <van-nav-bar
      left-arrow
      title="Yaaami"
      @click-left="onClickLeft"
    />
    <div class="content">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(item,index) in goodsImages" :key="index">
          <img :src="item">
        </van-swipe-item>
      </van-swipe>
      <div class="good_detail">
        <div class="name">
          {{ goodsDetail.name }}
        </div>
        <span
          v-if="goodsDetail.avg_score && goodsDetail.avg_score > 0"
          class="detail_scale"
        >
          <van-rate
            v-model="goodsDetail.avg_score"
            readonly
            color="#ffd21e"
            allow-half
            :count="5"
          />
        </span>
        <span
          v-if="goodsDetail.used_storage > 0"
          class="kucun"
          :class="(goodsDetail.used_storage < 10)?'reduce_storage':''"
        >{{ goodsDetail.used_storage }} Left</span>
        <span v-else class="no_storage" />
        <div v-if="tagList && tagList.length" class="goods_tag_list">
          <van-tag
            v-for="(item, index) in tagList"
            :key="index"
            :color="tagColorList[index]"
            size="large"
          >
            {{ item }}
          </van-tag>
        </div>
        <span class="sec">{{ goodsDetail.desc }}</span>
        <div class="all_price">
          <div>
            <span class="price"><i>$</i> {{ $filters.priceHandle(goodsDetail.price) }}</span>
            <span class="o_price">${{ $filters.priceHandle(goodsDetail.original_price) }}</span>
          </div>
          <div class="handle">
            <van-stepper
              v-model.number="goodsDetail.num"
              disable-input
              :class="goodsDetail.num>0?'':'no_minus'"
              :min="0"
              @plus="plusChange"
              @minus="minusChange"
            />
          </div>
        </div>
      </div>
      <div v-if="commentList && commentList.length" class="evaluate">
        <div class="title scale_title">
          Customer reviews
        </div>
        <div class="evaluate_list">
          <div v-for="(item, index) in commentList" :key="index" class="evaluate_item">
            <div class="user_info">
              <div class="head">
                <van-image
                  width="1rem"
                  height="1rem"
                  fit="cover"
                  :src="item.headSrc"
                />
              </div>
              <div class="name">
                <span class="user">{{ item.userName }}</span>
                <span class="scale"><van-rate
                  v-model="item.product_score"
                  readonly
                  color="#ffd21e"
                  allow-half
                  :count="5"
                /></span>
              </div>
              <div class="time">
                {{ $filters.timeFilter(item.created_at) }}
              </div>
            </div>
            <div class="comment">
              {{ item.content }}
            </div>
            <div v-if="item.pictureArr && item.pictureArr.length" class="comment_img_list">
              <div v-for="(url, i) in item.pictureArr" :key="i" class="img_style">
                <van-image
                  width="2rem"
                  height="2rem"
                  fit="cover"
                  :src="url"
                  @click="showImgList(item.pictureArr)"
                />
              </div>
            </div>
            <div v-if="item.follow_appraise" class="follow_evaluate">
              <p>{{ $filters.timeFilter(item.follow_appraise.updated_at) }} comments</p>
              <div class="comment">
                {{ item.follow_appraise.content }}
              </div>
            </div>
            <div class="comment_handle">
              <div class="handle_detail eval" @click="evalDialog(item)">
                <span />
                <van-icon name="comment-circle-o" />
              </div>
              <div class="handle_detail like">
                <span>{{ item.like_num }}</span>
                <van-icon
                  name="thumb-circle-o"
                  :class="item.login_user_like_status == 1?'liked':''"
                  @click="goLike(item)"
                />
              </div>
              <div class="handle_detail eval" @click="otherEvalDialog(item)">
                <van-icon name="more-o" />
              </div>
            </div>
            <div v-if="item.replyList && item.replyList.length">
              <div v-for="(ele, i) in item.replyList" :key="i" class="reply_info">
                <div class="user_info">
                  <div class="head">
                    <van-image
                      width="0.8rem"
                      height="0.8rem"
                      fit="cover"
                      :src="ele.headSrc"
                    />
                  </div>
                  <div class="name">
                    <span class="user">{{ ele.user_show_name }}</span>
                  </div>
                  <div class="time">
                    <span class="scale">{{ $filters.timeFilter(ele.created_at) }}</span>
                  </div>
                </div>
                <div class="comment">
                  {{ ele.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-action-bar>
      <van-action-bar-icon icon="shop-o" :badge="count" text="Cart" @click="goCart" />
      <van-action-bar-button color="#ff6034" type="warning" text="Add to Cart" @click="addCartFun('')" />
      <van-action-bar-button color="#ee0a24" type="danger" text="Buy Now" @click="goSumbit" />
    </van-action-bar>
    <!--  eslint-disable-next-line vue/no-v-model-argument -->
    <van-popup v-model:show="evalShow" position="bottom" :style="{ height: '30%' }">
      <div class="eval_show">
        <van-cell-group>
          <van-field
            v-model="evalComment"
            type="textarea"
            row="2"
            :autofocus="true"
            label=""
            placeholder=""
          />
        </van-cell-group>
        <van-button
          round
          type="primary"
          class="publish"
          :color="primaryColor"
          plain
          block
          @click="goPublish"
        >
          Publish
        </van-button>
      </div>
    </van-popup>
    <!--  eslint-disable-next-line vue/no-v-model-argument -->
    <van-image-preview v-model:show="showImgPreview" :images="previewImages" />
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Toast, Notify, Dialog } from 'vant'
import { useStore } from 'vuex'
import { goodsDetail, evaluateList, searchTag,
  evaluateReply, getReplyList } from '@/service/product'
import { addCart, delCart, updateCart } from '@/service/cart'
import { likeEvaluate, cancelLike } from '@/service/user'
import { getLocal, tempAdd, setLocalCart, delLocalCart } from '@/common/js/utils'
export default {
  name: 'ProductDetail',
  setup() {
    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      count: 0,
      proId: 0,
      stockId: 0,
      goodsImages: [],
      goodsDetail: {
        name: '',
        detail_desc: '',
        price: 0,
        storage: 0,
        num: 0
      },
      value: 0,
      commentList: [],
      tagList: [],
      evalShow: false,
      evalComment: '',
      evalId: 0,
      evalItem: {},
      showImgPreview: false,
      previewImages: []
    })
    onMounted(async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const userId = getLocal('userId')
      let goodsCart = {}
      if (userId) {
        await store.dispatch('updateCart')
        goodsCart = store.state.cartGoods
      } else {
        store.dispatch('updateLocalCart')
        goodsCart = store.state.cartGoods
      }
      state.proId = route.query.id
      state.stockId = route.query.stock_id
      if (state.proId) {
        const res = await goodsDetail({ product_id: state.proId, product_stock_id: state.stockId })
        const data = res.data.data.data[0]
        state.goodsDetail = data
        if (goodsCart[state.proId]) {
          state.goodsDetail.num = Number(goodsCart[state.proId])
        } else {
          state.goodsDetail.num = 0
        }
        state.goodsImages = data.detail_image_list
        setTagInfo(data)
        getEvaluateList()
      }
      Toast.clear()
    })
    const showImgList = (imgList) => {
      state.previewImages = imgList
      state.showImgPreview = true
    }
    const setTagInfo = async(data) => {
      var tagList = []
      if (data.product_attr) {
        tagList.push(data.product_attr)
      }
      if (data.tag_list && data.tag_list.length) {
        const promise = data.tag_list.map((item, i) => {
          // return
          return searchTag({
            tag_id: item
          })
        })
        Promise.all(promise)
          .then(allData => {
            allData.forEach((item, index) => {
              var tagItem = item.data.data[0]
              tagList.push(tagItem.Name)
            })
            state.tagList = tagList
          }).catch()
      } else {
        state.tagList = tagList
      }
    }
    const otherEvalDialog = async(item) => {
      state.evalId = item.id
      setReplyList(item)
    }
    const setReplyList = async(item) => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const res = await getReplyList({
        appraise_id: state.evalId
      })
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        var list = res.data.data
        list.forEach(item => {
          const num = Math.floor(Math.random() * 8)
          item.headSrc = require('./../assets/img/head/head' + num + '.png')
          item.user_show_name = item.user_show_name.substring(0, 3) + '****' + item.user_show_name.substring(7, item.user_show_name.length)
        })
        item.replyList = list
      }
      Toast.clear()
    }
    const count = computed(() => {
      return store.state.cartCount
    })
    const goCart = () => {
      router.push({ path: '/cart' })
    }
    const onClickLeft = (item) => {
      // 返回
      router.go(-1)
    }
    const addCartFun = async(count) => {
      var temp = tempAdd(state.goodsDetail.sale_time, state.goodsDetail.used_storage)
      if (!temp) {
        return false
      }
      if (!state.goodsDetail.num) {
        state.goodsDetail.num = 1
      }
      const userId = getLocal('userId')
      const saveCount = count || state.goodsDetail.num
      if (userId) {
        const res = await addCart({
          stock_id: state.goodsDetail.product_stock_id - 0,
          count: saveCount
        })
        if (res.data.code) {
          if (res.data.code === 130048) {
            Dialog.confirm({
              title: 'Warning',
              message: 'The delivery date of this product is different from other products in the shopping cart. Please go to the shopping cart to delete other products.'
            }).then(async() => {
              router.push({ path: `/cart` })
            })
              .catch(() => {
                // on cancel
                state.goodsDetail.num = 0
              })
          } else {
            state.goodsDetail.num = 0
            Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
          }
        } else {
          store.dispatch('updateCart')
        }
      } else {
        const id = state.proId - 0
        const saveTemp = setLocalCart(id, state.goodsDetail.num, state.goodsDetail.product_stock_id, state.goodsDetail.sale_time)
        if (saveTemp) {
          store.dispatch('updateLocalCart')
        } else {
          Dialog.confirm({
            title: 'Warning',
            message: 'The delivery date of this product is different from other products in the shopping cart. Please clear or checkout shopping cart first.'
          }).then(async() => {
            router.push({ path: `/cart` })
          })
            .catch(() => {
              state.goodsDetail.num = 0
            })
        }
      }
    }
    const goSumbit = () => {
      var temp = tempAdd(state.goodsDetail.sale_time, state.goodsDetail.used_storage)
      if (!temp) {
        return false
      }
      if (!state.goodsDetail.num) {
        state.goodsDetail.num = 1
      }
      sessionStorage.setItem('submitInfo', JSON.stringify([state.goodsDetail]))
      router.push({ path: '/submitOrder' })
    }
    const evalDialog = (item) => {
      const userId = getLocal('userId')
      if (!userId) {
        router.push({ path: `/login` })
        return false
      }
      state.evalId = item.id
      state.evalItem = item
      state.evalShow = true
    }
    const getEvaluateList = async() => {
      const userId = getLocal('userId')
      const res = await evaluateList({ product_id: state.proId, user_id: userId })
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        const list = res.data.data
        list.forEach(item => {
          const num = Math.floor(Math.random() * 8)
          item.headSrc = require('./../assets/img/head/head' + num + '.png')
          item.pictureArr = item.picture_urls.split(',').filter((s) => {
            return s && s.trim()
          })
          item.userName = item.user_name.substring(0, 3) + '****' + item.user_name.substring(7, item.user_name.length)
        })
        state.commentList = list
      }
    }
    const goLike = async(item) => {
      const userId = getLocal('userId')
      if (!userId) {
        router.push({ path: `/login` })
        return false
      }
      const id = item.id
      if (item.login_user_like_status === 1) {
        const res = await cancelLike({ appraise_id: id })
        if (res.data.code) {
          Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
        } else {
          item.login_user_like_status = 2
          item.like_num -= 1
        }
      } else {
        const res = await likeEvaluate({ appraise_id: id })
        if (res.data.code) {
          Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
        } else {
          item.login_user_like_status = 1
          item.like_num += 1
        }
      }
    }
    const goPublish = async() => {
      if (!state.evalComment) {
        Toast('Please input the content')
        return false
      }
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const res = await evaluateReply({
        at_apply_id: 0,
        appraise_id: state.evalId,
        content: state.evalComment
      })
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
        Toast.clear()
      } else {
        Toast.clear()
        Toast.success('Add success')
        state.evalShow = false
        state.evalComment = ''
        setReplyList(state.evalItem)
      }
    }
    const plusChange = async() => {
      var count = Number(state.goodsDetail.num) + 1
      addCartFun(count)
    }
    const minusChange = async() => {
      const goods_id = state.goodsDetail.id
      const sale_time = state.goodsDetail.sale_time
      const stock_id = state.goodsDetail.product_stock_id
      const count = Number(state.goodsDetail.num) - 1
      const userId = getLocal('userId')
      if (userId) {
        if (count > 0) {
          await updateCart({
            stock_id: stock_id,
            count: count
          })
        } else {
          await delCart({
            id_list: [stock_id]
          })
        }
        store.dispatch('updateCart')
      } else {
        if (count > 0) {
          setLocalCart(goods_id, count, stock_id)
        } else {
          delLocalCart(goods_id, stock_id)
        }
        store.dispatch('updateLocalCart')
      }
    }
    return {
      ...toRefs(state),
      onClickLeft,
      goCart,
      count,
      addCartFun,
      goSumbit,
      evalDialog,
      getEvaluateList,
      goLike,
      setTagInfo,
      goPublish,
      showImgList,
      otherEvalDialog,
      setReplyList,
      plusChange,
      minusChange
    }
  }
}
</script>
<style lang="less">
.product{
  .content {
    width: 100%;
    overflow: hidden;
    padding-bottom: 70px;
    .van-swipe {
      width: 100%;
      height: 6.9333rem;
      img {
        width: 100%;
      }
    }
    .good_detail {
      width: 100%;
      background: #fff;
      padding: 0.2667rem;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 0.4rem;
      .name {
        font-size: 0.48rem;
        font-weight: 500px;
        margin: 0;
        line-height: 0.6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        .kucun {
          font-size: 0.32rem;
          color: #bbb;
          width: 1.8rem;
          text-align: right;
        }
        .reduce_storage {
          color: red;
          font-weight: 500;
        }
        .no_storage {
          width: 1rem;
          height: 1rem;
          display: flex;
          background: url('./../assets/img/soldout.png') no-repeat center;
          background-size: 100%;
          position: absolute;
          right: 0;
          top: 0.1333rem;
        }
      }
      .sec {
        font-size: 0.4267rem;
        display: block;
        line-height: 0.6333rem;
        margin-bottom: 0.2667rem;
        color: #666;
      }
      .goods_tag_list {
        margin-bottom: 0.2rem;
        .van-tag--large {
          margin-right: 0.1333rem;
          margin-bottom: 0.1333rem;
        }
      }
      .date {
        color: #ed6a0c;
      }
      .all_price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.3733rem;
        .price {
          font-weight: 700;
          font-size: 0.48rem;
          margin-right: 0.2667rem;
          color: red;
          i {
            font-style: normal;
            font-size: 0.3733rem;
          }
        }
        .o_price {
          font-size: 0.3733rem;
          font-weight: 500;
          color: #aaa;
          text-decoration: line-through;
        }
        .no_minus {
          .van-stepper__minus {
            display: none;
          }
          .van-stepper__input {
            display: none;
          }
        }
      }
    }
    .detail_scale {
      width: 100%;
      font-size: 0.48rem;
      font-weight: 500px;
      margin: 0;
      line-height: 0.6rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.2rem;
      .kucun {
        font-size: 0.32rem;
        color: #bbb;
        width: 1.8rem;
        text-align: right;
      }
      .reduce_storage {
        color: red;
        font-weight: 500;
      }
      .no_storage {
        width: 1rem;
        height: 1rem;
        display: flex;
        background: url('./../assets/img/soldout.png') no-repeat center;
        background-size: 100%;
        position: absolute;
        right: 0;
        top: 0.1333rem;
      }
    }
    .evaluate {
      width: 100%;
      background: #fff;
      padding: 0.4rem;
      box-sizing: border-box;
      .title {
        width: 100%;
        height: 1.0667rem;
        line-height: 0.8rem;
        font-size: 0.4533rem;
        color: gray;
      }
      .scale_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .evaluate_list {
        width: 100%;
        .go_more {
          margin: auto;
          text-align: center;
          padding: 0.4rem;
          width: 100%;
          box-sizing: border-box;
        }
        .follow_evaluate {
          p {
            color: #ed6a0c;
          }
        }
        .evaluate_item {
          border-bottom: 1px solid #e5e5e5;
          padding: 0.2667rem 0;
          &:last-child {
            border-bottom: none;
          }
          .user_info {
            width: 100%;
            display: flex;
            flex-direction: row;
            position: relative;
            .head {
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 0.2667rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name {
              display: flex;
              flex-direction: column;
              .user {
                font-size: 0.3733rem;
              }
            }
            .time {
              position: absolute;
              right: 0;
              font-size: 0.3733rem;
              color: #666;
            }
          }
          .reply_info {
            .head {
              width: 0.8rem;
              height: 0.8rem;
            }
            .name {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 0.8rem;
              height: 0.8rem;
            }
            .time {
              padding-top: 0.1333rem;
            }
          }
          .reply_center {
            text-align: center;
            font-size: 0.3733rem;
            color: #666;
          }
          .comment {
            width: 100%;
            line-height: 1.0667rem;
            font-size: 0.3733rem;
          }
          .liked {
            color: red;
          }
          .comment_img_list {
            width: 100%;
            .img_style {
              display: inline-block;
              width: 2rem;
              height: 2rem;
              margin-right: 0.2667rem;
              box-sizing: border-box;
              img {
                width: 100%;
              }
            }
          }
          .comment_handle {
            width: 100%;
            height: 1.0667rem;
            line-height: 1.2rem;
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            .handle_detail {
              font-size: 0.3733rem;
              margin-left: 0.4rem;
              display: flex;
              justify-content: space-around;
              color: #aaa;
              .van-icon {
                font-size: 0.65rem;
                margin-left: 0.1rem;
              }
              .van-icon-comment-circle-o::before {
                padding-top: 0.25rem;
              }
              .van-icon-thumb-circle-o::before {
                padding-top: 0.25rem;
              }
              .van-icon-more-o::before {
                padding-top: 0.25rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
