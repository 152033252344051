import axios from '../utils/axios'
import { Toast } from 'vant'

export const stripePay = {
  state: {
    payMethods: [],
    payLoading: false,
    stripe: null,
    stripeCard: null,
    server_security_key: '',
    server_pub_key: ''
  },
  methods: {
    async getKey() {
      const res = await new Promise((resolve, reject) => {
        axios({
          url: `/api/pay/config`,
          method: 'GET',
          params: {
          }
        }).then(res => {
          if (res.data.code) {
            Toast(res.data.message)
            resolve(null)
          } else {
            stripePay.state.server_security_key = res.data.data.s_key
            stripePay.state.server_pub_key = res.data.data.p_key
            resolve(res.data.data.s_key)
          }
        }).catch(e => {
          resolve(null)
        })
      })
      return res
    },
    // 获取customer
    async getCustomer() {
      const customer = sessionStorage.getItem('customer')
      if (customer) return JSON.parse(customer)
      const res = await new Promise((resolve, reject) => {
        axios({
          url: `/api/customer`,
          method: 'GET',
          params: {
          }
        }).then(res => {
          if (res.data.code) {
            Toast(res.data.message)
            resolve(null)
          } else {
            sessionStorage.setItem('customer', JSON.stringify(res.data.data))
            resolve(res.data.data)
          }
        }).catch(e => {
          resolve(null)
        })
      })
      return res
    },
    // 创建 SetupIntent
    async createSetupIntent() {
      const customer = await this.getCustomer()
      if (!customer) return
      await this.getKey()
      const res = await new Promise((resolve, reject) => {
        axios({
          url: `https://api.stripe.com/v1/setup_intents`,
          stripe: true,
          wwwForm: true,
          method: 'POST',
          data: {
            customer: customer.customer_id
          },
          headers: {
            Authorization: `Bearer ${stripePay.state.server_security_key}`
          }
        }).then(res => {
          resolve(res.data)
        }).catch(e => {
          Toast(e && e.data.error.message)
          resolve(null)
        })
      })
      return res
    },
    // 获取绑定的信用卡
    async getCardList(cusId) {
      await this.getKey()
      const res = await new Promise((resolve, reject) => {
        axios({
          url: `https://api.stripe.com/v1/customers/${cusId}/sources`,
          stripe: true,
          method: 'GET',
          params: {
            object: 'card'
          },
          headers: {
            Authorization: `Bearer ${stripePay.state.server_security_key}`
          }
        }).then(res => {
          resolve(res.data)
        }).catch(e => {
          resolve(null)
        })
      })
      return res
    },
    // 获取客户paymentMethods
    // @see https://stripe.com/docs/api/payment_methods/list#list_payment_methods-type
    async getPayMethods() {
      const customer = await this.getCustomer()
      await this.getKey()
      const res = await new Promise((resolve, reject) => {
        axios({
          url: `https://api.stripe.com/v1/payment_methods`,
          stripe: true,
          method: 'GET',
          params: {
            customer: customer.customer_id,
            type: 'card'
          },
          headers: {
            Authorization: `Bearer ${stripePay.state.server_security_key}`
          }
        }).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          resolve(null)
        })
      })
      return res
    },

    // 预创建订单（获取publishable_key，client_secret）
    createPay(params, curPayMethod, router) {
      if (stripePay.state.submitLock) {
        return
      }
      stripePay.state.submitLock = true
      stripePay.state.showCreditInfo = true
      Toast.loading({
        message: 'Processing payment now. Please wait...',
        forbidClick: true
      })
      axios({ url: '/api/pay/create', method: 'POST', data: params }).then(res => {
        stripePay.state.submitLock = false
        Toast.clear()
        if (res.data.code) {
          Toast(res.data.message)
        } else {
          const payData = res.data.data
          this.pay(payData, params, curPayMethod, router)
        }
      })
    },
    setupElements(data) {
      // eslint-disable-next-line no-undef
      const stripe = Stripe(data.publishable_key)
      const client_secret = data.client_secre
      const elements = stripe.elements()
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
      var card = elements.create('card', { style: style })
      card.mount('#card-element')
      return { stripe, card, client_secret }
    },
    pay(payData, params, curPayMethod, router) {
      // eslint-disable-next-line no-undef
      const stripe = Stripe(payData.p_key)
      if (stripePay.state.payLoading) {
        return
      }
      Toast.loading({
        message: 'Processing payment now. Please wait...',
        forbidClick: true
      })
      stripePay.state.payLoading = true
      stripe.confirmCardPayment(payData.c_secret, {
        payment_method: curPayMethod.id
      }).then((result) => {
        Toast.clear()
        stripePay.state.payLoading = false
        if (result.error) {
          Toast(result.error.message)
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            Toast.success('Payment successful!')
            router.push({ path: `/orderDetail`, query: { orderId: params.order_id, type: 'done' }})
            sessionStorage.setItem('submitInfo', '')
          }
        }
      })
    },
    // 更新支付方式
    async updatePayMothod() {
      stripePay.state.payMethods = await this.getPayMethods()
    }
  }

}
