<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="cart">
    <!--  eslint-disable-next-line vue/attribute-hyphenation -->
    <header-bar :headTitle="headerTitle" :show-menu="true" />
    <div class="content">
      <div v-if="cartGoodList.length" class="goods_list">
        <div v-for="(dateGoods, dateIndex) in cartGoodList" :key="dateIndex" class="cart-date-item">
          <div class="date">
            Delivery Date: {{ dateGoods.date }}
          </div>
          <template v-if="dateGoods.goodList.length">
            <div class="tilte_box">
              <van-notice-bar
                v-if="noticeText"
                left-icon="map-marked"
                :text="noticeText"
              />
              <div v-if="feeResult" class="fee_box">
                <img src="../assets/img/fee.png" alt="" srcset="">
                <div>Free basic freight for over ${{ $filters.priceHandle(fee) }}</div>
              </div>
            </div>
            <div v-for="(item, index) in dateGoods.goodList" :key="index" class="goods_item">
              <div class="left" @click="goToDetail(item.id, item.product_stock_id)">
                <img :src="item.pictures" alt="" srcset="">
              </div>
              <div class="right">
                <p class="name">
                  {{ item.name }}
                </p>
                <span class="des">{{ item.des }}</span>
                <div class="all_price">
                  <span class="price"><i>$</i>{{ $filters.priceHandle(item.price) }}</span>
                  <div class="handle">
                    <van-stepper
                      v-model="item.num"
                      disable-input
                      theme="round"
                      :min="0"
                      @plus="plusChange(item,index)"
                      @minus="minusChange(item,index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-else>
        <div class="no_cart">
          <van-icon name="shopping-cart-o" />
          <p>No data</p>
        </div>
      </div>
      <van-submit-bar :price="allPrice" currency="$" button-text="Sumbit" @submit="onSubmit" />
    </div>
    <nav-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, Dialog, Notify } from 'vant'
import navBar from '@/components/NavBar'
import HeaderBar from '@/components/HeaderBar'
import { addCart, updateCart, getCart, delCart } from '@/service/cart'
import { getFee } from '@/service/user'
import { getMoreGoodsListNew } from '@/service/product'
import { useStore } from 'vuex'
import { getLocal, setLocalCart, delLocalCart } from '@/common/js/utils'

export default {
  name: 'Home',
  components: {
    navBar,
    HeaderBar
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      headerTitle: 'My cart',
      cartList: [],
      cartGoodList: [],
      allChecked: true,
      allPrice: 0,
      deleteStatus: false,
      checkedList: [],
      noticeText: '',
      sale_time: '',
      fee: 0, // 满减金额
      feeResult: false // 满减条件
    })
    onMounted(() => {
      initCartGoods()
      getFullMinus().catch()
    })

    const initCartGoods = async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const userId = getLocal('userId')
      let cartMap
      if (userId) {
        const res = await getCart({})
        cartMap = res.data.data
      } else {
        store.dispatch('updateLocalCart')
        cartMap = store.state.cartGoods
      }
      // 按日期获取购物车商品列表
      await getCartGoodsMap(cartMap)
      Toast.clear()
    }

    const onClickRight = () => {
      state.deleteStatus = !state.deleteStatus
    }

    // 根据商品库存ID去购物车中查找对应商品的数量
    const getCartNum = (productStockId) => {
      for (const date in store.state.cartGoods) {
        const productMap = store.state.cartGoods[date].productMap
        if (productMap[productStockId]) {
          return Number(productMap[productStockId])
        }
      }
      return 0
    }

    // 获取购物车商品列表(分日期获取)
    const getCartGoodsMap = async(cartMap) => {
      const goods = []
      let total = 0

      const promises = Object.keys(cartMap).map(async(date) => {
        try {
          const stock_object = cartMap[date].stockMap
          const res = await getMoreGoodsListNew({ stock_object })
          const goodList = res.data.data
          goodList.forEach(item => {
            item.num = getCartNum(item.product_stock_id)
            item.checked = true
            total += item.num * item.price
          })
          goods.push({
            date,
            goodList
          })
        } catch (e) {
          console.log(`fetchGoodsMapInCart error ${date}`, e)
        }
      })
      await Promise.all(promises)

      state.allPrice = total
      state.cartGoodList = goods
      state.checkedMap = goods
      // console.log({
      //   cartGoodList: goods,
      //   t0tal: total
      // })
    }
    // 增加商品数量
    const plusChange = async(item, index) => {
      const goods_id = item.id
      const count = Number(item.num) + 1
      const userId = getLocal('userId')
      if (userId) {
        await addCart({
          stock_id: item.product_stock_id,
          count: count
        })
        store.dispatch('updateCart')
      } else {
        setLocalCart(goods_id, count, item.product_stock_id)
        store.dispatch('updateLocalCart')
      }
      calculationTotal()
    }
    const minusChange = async(item, index) => {
      const goods_id = item.id
      const count = Number(item.num) - 1
      const userId = getLocal('userId')
      if (count === 0) {
        Dialog.confirm({
          title: 'Warning',
          message: 'Are you sure you want to empty this product?'
        })
          .then(() => {
            // on confirm
            if (userId) {
              deleteAll(item.product_stock_id)
            } else {
              delLocalCart(goods_id, item.product_stock_id)
              store.dispatch('updateLocalCart')
              // state.cartList.splice(index, 1)
            }
          })
          .catch(() => {
            // on cancel
            item.num = 1
          })
      } else {
        if (userId) {
          minusCount(item)
        } else {
          setLocalCart(goods_id, count, item.product_stock_id)
          store.dispatch('updateLocalCart')
        }
      }
    }
    // 减少商品数量
    const minusCount = async(item) => {
      await updateCart({
        stock_id: item.product_stock_id,
        count: Number(item.num) - 1
      })
      store.dispatch('updateCart')
      calculationTotal()
    }
    const goToDetail = (id, stock_id) => {
      router.push({ path: '/goodsDetail', query: { id, stock_id }})
    }
    const checkedChange = (item, index) => {
      calculationTotal()
    }
    const calculationTotal = () => {
      const checkedList = []
      let total = 0
      // state.cartList.forEach(item => {
      //   if (item.checked) {
      //     checkedList.push(item)
      //     total += item.price * item.num
      //   }
      // })
      const goods = state.cartGoodList.flatMap(item => item.goodList)
      for (let i = 0; i < goods.length; i++) {
        const item = goods[i]
        if (item.checked) {
          checkedList.push(item)
          total += item.price * item.num
        }
      }
      state.allPrice = total
      state.checkedList = checkedList
    }
    const allGoodsCheck = () => {
      if (state.allChecked) {
        var price = 0
        state.cartList.forEach(item => {
          item.checked = true
          price += item.price * item.num
        })
        state.allPrice = price
        state.checkedList = state.cartList
      } else {
        state.cartList.forEach(item => {
          item.checked = false
        })
        state.allPrice = 0
        state.checkedList = []
      }
    }
    const deleteAll = async(stock_id) => {
      await delCart({
        id_list: [stock_id]
      })
      store.dispatch('updateCart')
      // calculationTotal()
      initCartGoods()
    }
    const onSubmit = () => {
      if (state.cartGoodList.length === 0) {
        Toast('Please add purchase item')
        return
      }
      console.log('state.cartGoodList', state.cartGoodList)
      sessionStorage.setItem('submitInfo', JSON.stringify(state.cartGoodList))
      router.push({ path: '/submitOrder' })
      // if (state.checkedList.length === 0) {
      //   Toast('Please add purchase item')
      // } else {
      //   var submitInfo = JSON.stringify(state.checkedList)
      //   sessionStorage.setItem('submitInfo', submitInfo)
      //   router.push({ path: `/submitOrder` })
      // }
    }

    // 是否有满减
    const getFullMinus = async() => {
      const res = await getFee({})
      if (res.data.code) {
        Notify({ message: res.data.message, color: '#ad0000', background: '#ffe1e1' })
      } else {
        const fee = res.data.data
        if (fee !== 0) {
          state.fee = fee
          state.feeResult = true
        }
      }
    }
    return {
      ...toRefs(state),
      goToDetail,
      onSubmit,
      onClickRight,
      checkedChange,
      calculationTotal,
      allGoodsCheck,
      deleteAll,
      plusChange,
      minusChange,
      minusCount,
      getFullMinus,
      getCartGoodsMap
    }
  }
}
</script>
<style lang="less">
@import '../common/style/mixin';

.cart {
  .van-nav-bar__right {
    .van-nav-bar__text {
      color: #ee0a24;
      font-weight: 700;
    }
  }

  .content {
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 96px);
    margin-bottom: .4rem /* 30/75 */;
  }

  .goods_list {
    padding: .266667rem /* 20/75 */;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 146px);
    overflow: auto;

    .cart-date-item {
      margin-bottom: 15px;

      .date {
        margin-bottom: 10px;
        color: rgba(69, 90, 100, .6);
        font-weight: 400;
        font-size: 0.38rem;
        line-height: 16px;
      }
    }

    .tilte_box {
      position: relative;

      .fee_box {
        position: absolute;
        top: 0;
        right: 10px;
        display: flex;
        height: 32px;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        div {
          font-size: 12px;
          color: #666;
          margin-top: 5px;
        }
      }
    }

    .goods_item {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      margin-bottom: .266667rem /* 20/75 */;
      background: #fff;
      padding: .266667rem /* 20/75 */;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }

      .goods_radio {
        margin-right: 0.1333rem;
        display: flex;
        align-items: center;
      }
    }
  }

  .van-submit-bar {
    bottom: 50px;
  }

  .no_cart {
    padding-top: 3rem;
    text-align: center;
    color: rgb(153, 153, 153);

    .van-icon {
      font-size: 1.4rem;
    }

    p {
      font-size: 0.4267rem;
    }
  }
}

</style>
